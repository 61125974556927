import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAccessPermissions } from '../utils/AccessPermissionsUtils';

const ProtectedRoute = ({ component: Component, setPageHeader, accessPermissions, ...rest }: any) => {

    return (
        <Route
            {...rest}
            render={props => {
                const data = JSON.parse(localStorage.getItem('user') as any);
                const isAdmin = data ? data.type === 'admin' ? true : false : false;
                const hasRole = data ? data.role_id ? true : false : false;
                const path = `/${props.location.pathname.split('/')[1]}`;
                if (localStorage.getItem('access_token') && (isAdmin || hasRole)) {
                    return <Component
                        {...props}
                        setPageHeader={setPageHeader}
                        accessPermissions={getAccessPermissions(path)}
                    />
                }
                else {
                    return <Redirect to={
                        {
                            pathname: '/',
                            state: {},
                        }
                    } />
                }
            }
            }
        />

    );
}

export default ProtectedRoute;