/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Layout, Result } from 'antd';
import SideMenuView from "./component/sidebar/SideMenuView";
import HeaderView from "./component/header/HeaderView";
import './LayoutView.less'
import { useLocation } from "react-router-dom";
import { getAccessPermissions } from "../../utils/AccessPermissionsUtils";

const { Content } = Layout;

const LayoutView = ({ children }: any) => {

    const [pageHeader, setPageHeader] = useState('');
    const [headerItemChange, setHeaderItemChange] = useState(true)
    const [headerItems, setHeaderItems] = useState(null)
    const [routes, setRoutes] = useState(React.Children.map(children, (child) => child.props.path))



    useEffect(() => {
    }, [])

    const child = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            // setPageHeader: setPageHeader
            setPageHeader,
            headerItems,
            setHeaderItemChange,
        });
    });
    const handlePath = (path: any) => {
        const pathSegment = path.split('/')
        if (pathSegment.length > 2) {
            pathSegment.pop();
            return pathSegment.join('/')
        }
        return path
    }
    const location = useLocation()
    const accessPermissions = getAccessPermissions(handlePath(location.pathname))

    return (
        <Layout className="main-layout" hasSider>
            <SideMenuView />
            <Layout style={{
                marginLeft: 250,
            }}>
                <HeaderView />

                <Content className='content-layout'>
                    {
                        !routes.includes(handlePath(location.pathname)) ? <Result
                            status="404"
                            title="404"
                            subTitle="Sorry, the page you visited does not exist."
                        /> : location.pathname !== '/dashboard' && routes.includes(handlePath(location.pathname)) && !accessPermissions.access.read ?
                            <Result
                                status="403"
                                title="403"
                                subTitle="Sorry, you are not authorized to access this page."
                            /> : child
                    }
                </Content>
            </Layout>
        </Layout>
    );
}


export default LayoutView