import axios from "axios";
import React, { Component } from "react";
import {
  BrowserRouter as Router, Route,
  Switch
} from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader";
import lazyComponentLoader from "./hoc/LazyLoader";
import ProtectedRoute from "./hoc/ProtectedRoute";
import PublicRoute from "./hoc/PublicRoute";
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";

interface IAppState {
  user: any;
  isLoading: Boolean;
  isLoggedIn: Boolean;
  isAdmin: Boolean;
}
const Login: any = lazyComponentLoader(
  () => import("./views/auth/login/LoginView")
);
const ResetView: any = lazyComponentLoader(
  () => import("./views/auth/reset/ResetView")
);
const DashboardView: any = lazyComponentLoader(
  () => import("./views/dashboard/DashboardView")
);
const EmployeesView: any = lazyComponentLoader(
  () => import("./views/employees/EmployeesView")
);
const EmployeeDetailsView: any = lazyComponentLoader(
  () => import("./views/employees/components/EmployeeDetailsView")
);
const AttendanceView: any = lazyComponentLoader(
  () => import("./views/attendance/AttendanceView")
);
const LeaveView: any = lazyComponentLoader(
  () => import("./views/leave/LeaveView")
);
const OvertimeView: any = lazyComponentLoader(
  () => import("./views/overtime/OvertimeView")
);
const LoanView: any = lazyComponentLoader(
  () => import("./views/loan/LoanView")
);
const ReportsView: any = lazyComponentLoader(
  () => import("./views/reports/ReportsView")
);
const AssetsView: any = lazyComponentLoader(
  () => import("./views/assets/AssetsView")
);
const ClientsView: any = lazyComponentLoader(
  () => import("./views/clients/ClientsView")
);
const ProjectsView: any = lazyComponentLoader(
  () => import("./views/projects/ProjectsView")
);
const ProjectView: any = lazyComponentLoader(
  () => import("./views/projects/components/ProjectView")
);
const BillablesView: any = lazyComponentLoader(
  () => import("./views/billables/BillablesView")
);
const ReceivablesView: any = lazyComponentLoader(
  () => import("./views/receivables/ReceivablesView")
);
const PermissionsView: any = lazyComponentLoader(
  () => import("./views/permissions/PermissionsView")
);
const PermissionView: any = lazyComponentLoader(
  () => import("./views/permissions/components/PermissionDetailsView")
);
const UserManagementView: any = lazyComponentLoader(
  () => import("./views/user-management/UserManagementView")
);
const CalendarView: any = lazyComponentLoader(
  () => import("./components/CalendarView")
);
const CompanySettingsView: any = lazyComponentLoader(
  () => import("./views/company-settings/CompanySettingsView")
);
const OnBoardingCheckList: any = lazyComponentLoader(
  () => import("./views/checklists/CheckListView")
);
const BankManagementView: any = lazyComponentLoader(
  () => import("./views/banks/BankManagementView")
)

class App extends Component<any, IAppState> {
  constructor(props: any) {
    super(props);
    //initialize state here
    this.state = {
      user: {},
      isLoading: false,
      isLoggedIn: false,
      isAdmin: false
    };

    axios.interceptors.response.use(
      function (response) {

        return response;
      },
      async (error) => {
        if (error.response) {
          this.showError(error.response.data.errors);
        } else {
          this.showError(error);
        }
        return Promise.reject(error);
      }
    );
  }

  componentDidMount() {
    const data: any = localStorage.getItem('user');
    this.setState({
      user: JSON.parse(data),
      isLoggedIn: localStorage.getItem('access_token') ? true : false,
    });
  }

  showError(errors: any) {
    let content = null;
    if (Array.isArray(errors)) {
      content = (
        <div>
          {errors.map((item: any, index: number) => {
            return (
              <div key={Math.random().toString(6)}>
                {item.message.includes('timeout') ||
                  item.message.includes('access_token') ||
                  item.message.includes('jwt expired') ||
                  item.message.includes('authorization')
                  ? 'Your session has timed out.'
                  : item.message}
              </div>
            );
          })}
        </div>
      );
    } else {
      content = (
        <div>
          {errors.message.includes('Network Error')
            ? 'No network connection. Make sure that WI-FI or Cellular Mobile Data is turned on, then try again.'
            : errors.message.includes('jwt expired')
              ? 'Your session has timed out.'
              : errors.message}
        </div>
      );
    }

    DialogUtils.error(content);
  }

  onOk() {
    localStorage.clear();
    window.location.replace('/');
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <PublicRoute
              exact path='/'
              component={Login}
            />
            <Route
              exact path='/reset-password/:token'
              component={ResetView}
            />
            <LayoutView>
              <ProtectedRoute
                exact
                path='/dashboard'
                component={DashboardView}
              />
              <ProtectedRoute
                exact
                path='/employees'
                component={EmployeesView}
              />
              <ProtectedRoute
                exact
                path='/employees/:id'
                component={EmployeeDetailsView}
              />
              <ProtectedRoute
                exact
                path='/attendance'
                component={AttendanceView}
              />
              <ProtectedRoute
                exact
                path='/leave'
                component={LeaveView}
              />
              <ProtectedRoute
                exact
                path='/overtime'
                component={OvertimeView}
              />
              <ProtectedRoute
                exact
                path='/loan'
                component={LoanView}
              />
              <ProtectedRoute
                exact
                path='/customers'
                component={ClientsView}
              />
              <ProtectedRoute
                exact
                path='/projects'
                component={ProjectsView}
              />
              <ProtectedRoute
                exact
                path='/billables'
                component={BillablesView}
              />
              <ProtectedRoute
                exact
                path='/receivables'
                component={ReceivablesView}
              />
              <ProtectedRoute
                exact
                path='/projects/:id'
                component={ProjectView}
              />
              <ProtectedRoute
                exact
                path='/reports'
                component={ReportsView}
              />
              <ProtectedRoute
                exact
                path='/assets'
                component={AssetsView}
              />
              <ProtectedRoute
                exact
                path='/accessibility'
                component={PermissionsView}
              />
              <ProtectedRoute
                exact
                path='/on-boarding-checklist'
                component={OnBoardingCheckList}
              />
              <ProtectedRoute
                exact
                path='/accessibility/:id'
                component={PermissionView}
              />
              <ProtectedRoute
                exact
                path='/user-management'
                component={UserManagementView}
              />
              <ProtectedRoute
                exact
                path="/banks"
                component={BankManagementView}
              />
              <ProtectedRoute
                exact
                path='/calendar'
                component={CalendarView}
              />
              <ProtectedRoute
                exact
                path='/company-settings'
                component={CompanySettingsView}
              />
            </LayoutView>
          </Switch>
        </Router>
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );
  }
}

export default App;
