import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Avatar, Image, Space, Dropdown, Menu,  } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import './HeaderView.less'
import { ExitToApp } from '@material-ui/icons';
import { CaretDownOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

const header_title = { margin: 0 };
const user_details = { marginRight: 10 };
const user_name = { margin: 0, fontWeight: 600, fontSize: 17 };
const user_position = { margin: 0, fontWeight: 400, fontSize: 14, color: 'gray' };
const avatar_style = { width: 40 }

const HeaderView = (props: any) => {
    const [user, setUser]: any = useState({})
    const [path, setPath]: any = useState('employees')
    const location = useLocation();
    const history = useHistory();

    const logout_btn_icon = { marginRight: 10 }

    const fallback_url = process.env.REACT_APP_FALLBACK_URL;

    const getUser = async () => {
        const user = JSON.parse(localStorage.getItem('user') as any);
        setUser({
            ...user,
            imageUrl: '',
            position: user.employee_id ? user.employee_id.position : 'Admin',
            name: user.first_name + " " + user.last_name
        });
        let tmp_path = location.pathname.slice(1)
        let [path_name] = tmp_path.split('/');
        setPath(path_name === 'user-management' ? 'User' : path_name === 'company-settings' ? 'Company Settings' : path_name === 'banks' ? 'Bank Account' : path_name[0].toUpperCase() + path_name.slice(1))
    }
    const onLogout = () => {
        localStorage.clear();
        history.push('/');
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span onClick={onLogout} className='dashboard-logout-btn' >
                            <Row justify='space-between'>
                                <ExitToApp style={logout_btn_icon} />
                                LOGOUT
                            </Row>
                            <Space direction='horizontal' />
                        </span>
                    ),
                },
            ]}
        />
    );

    useEffect(() => {
        const initialize = () => {
            getUser();
        };
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            <Row align='middle' className='header-class'>
                <Col span={8}>
                    <Row>
                        <Title level={3} style={header_title}>{path}</Title>
                    </Row>
                </Col>
                <Col span={16}>
                    <Row justify='end' align='middle'>
                        <Space direction={'vertical'} size={0} style={user_details}>
                            <Row justify='end'>
                                <Text style={user_name}>{user.name}</Text>
                            </Row>
                            <Row justify='end'>
                                <Text style={user_position}>{user.position}</Text>
                            </Row>
                        </Space>
                        <Row>
                            <Dropdown
                                placement="bottomRight"
                                overlay={menu}
                                arrow
                                trigger={['click']}
                            >
                                <Space size={3}>
                                    <Avatar
                                        size={45}
                                        src={<Image
                                            src={user.imageUrl}
                                            style={avatar_style}
                                            preview={false}
                                            fallback={fallback_url}
                                        />}
                                        className='header-avatar'
                                    />
                                    <CaretDownOutlined className='icon-dropdown' />
                                </Space>
                            </Dropdown>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default HeaderView